@use '../../foundation/system' as system;

.c-inputBorder {
  margin: 10px 0 0;
  padding: 10px 10px;
  background-color: system.$c-white;
  border: 1px solid #a5a5a5;
  @include system.text-base(10px, normal);

  &::placeholder {
    @include system.text-base(10px, normal);
    color: #929292;
  }

  &.error {
    border: 1px solid system.$c-red;
  }
}

.l-formInput__date {
  div {
    position: relative;
    display: flex;
    justify-content: space-between;
    

    input {
      width: calc((100% - 33px) / 2);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      content: '〜';
    }
  }
}
@use '../../foundation/system' as system;

.c-mypageList {
  a {
    position: relative;
    display: block;
    padding: 20px 25px 36px;

    &::after {
      position: absolute;
      bottom: 16px;
      right: 10px;
      width: 180px;
      height: 7px;
      background: no-repeat url(/assets/img/listArrow.svg);
      background-size: 100%;
      content: '';
    }
  }

  &__time {
    @include system.text-base(14px);

    &::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome("\f017");
      font-weight: 400;
    }

    span {
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }

  &__hospital {
    margin: 10px 0 0;
    @include system.title-base();
    color: system.$c-blue;

    span {
      display: inline-block;
      margin: 0 0 0 5px;
      padding: 0 5px;
      border-left: 1px solid system.$c-black;
      border-right: 1px solid system.$c-black;
      @include system.text-base(14px)
    }
  }

  &__name {
    margin: 10px 0 0;
    @include system.text-base(12px)
  }

  &__label {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 1;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    background-color: system.$c-red;
    @include system.text-bold(10px);
    color: system.$c-white;
  }

  &.red {
    a {
      background-color: #FFE7E7;
      box-shadow: 0px 2px 4px rgba(177, 124, 124, 0.25);
    }
  }

  &.gray {
    a {
      background-color: #F5F5F5;
    }
  }

  & + li {
    margin: 45px 0 0;
  }
}
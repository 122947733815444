@use '../../foundation/system' as system;

.c-stores {
  width: 100%;
  padding: 25px 16px;
  background-color: system.$c-white;

  &__shop {
    margin: 0 0 12px;
    @include system.title-base();
  }

  &__detail {
    margin: 10px 0 0;
  }

  &__bold {
    @include system.text-bold();
    line-height: 1.5;
  }

  &__note {
    display: flex;

    dd + dd{
      margin: 0 0 0 5px;
      padding: 0 0 0 5px;
      border-left: 1px solid system.$c-black;
    }
  }

  &__contact {
    display: flex;

    dd,
    dt {
      line-height: 1.5;
    }

    dt:nth-of-type(2) {
      margin: 0 0 0 5px;
      padding: 0 0 0 5px;
      border-left: 1px solid system.$c-black;
    }
  }

  & + .c-stores {
    margin: 22px 0 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 35px;
    margin: 24px auto 0;
    border-radius: 20px;
    background-color: system.$c-black;
    @include system.title-base();
    color: system.$c-white;
  }
}
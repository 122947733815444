/* system
--------------------------- */
// font
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');


// color system
// ---------------------
$c-red: #FAA04D;
$c-gray: #F5F5F5;
$c-skyBlue: #32B5FF;
$c-blue: #42b19d;
$c-brown: #D48B5E;
$c-lightGreen: #F4F4F4;
$c-black: #222;
$c-dark: #3D4754;
$c-white: #fff;

// border
$border-gray: 1px solid #b8b8b8;

// transition
// ---------------------
@mixin transition-base($second: 0.3s) {
	transition: all $second;
}

// content max-width
// ---------------------
@mixin content-maxwidth($px: 1200px) {
	max-width: $px;
	width: 100%;
	padding: 0 27px;
}

// font system
// ---------------------
body {
	font-size: 12px;
	font-family: 'Noto Sans JP', sans-serif;
}

// text
// ---------------------
@mixin text-base($size: 12px, $weight: normal) {
	color: $c-black;
	font-size: $size;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: $weight;
}

@mixin title-base($size: 14px) {
	color: $c-black;
	font-size: $size;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 700;
	line-height: 1.25;
}

@mixin text-bold($size: 12px) {
	font-size: $size;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 700;
	line-height: 1.25;
}

@mixin titleLine($width) {
	position: relative;
	padding: 0 0 15px;

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: $width;
		height: 1px;
		background-color: #b8b8b8;
		content: '';
	}
}

@mixin newsTime() {
	width: 160px;
	background-color: #f4f4f4;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	@include text-base(10px);
	color: #3A3A3A;
	line-height: 20px;

	&::before {
		margin: 0 5px 0 0;
		padding: 0 0 0 23px;
		@include fontAwesome("\f017");
		font-weight: 400;
	}

	span:nth-of-type(1) {
    display: inline-block;
    margin: 0 5px 0 0;
	}
}

@mixin searchResultTitle() {
	position: relative;
	margin: 47px 0 0;
	padding: 0 0 15px;
	@include text-bold();
	text-align: center;

	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		width: 25px;
		height: 3px;
		background-color: $c-brown;
		content: '';
	}

	span {
		display: inline-block;
		color: $c-blue;

		& + span {
			margin: 0 0 0 5px;
		}
	}
}

// section
// ---------------------
@mixin sectionGrayBack {
  width: 100%;
  padding: 20px 27px;
  background-color: $c-gray;

  h2 {
    @include title-base();
  }
}

// background design
// ---------------------
@mixin formBackground() {
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 91%;
		height: 100%;
		background-color: #f4f4f4;
		content: '';
	}

	&::before {
		position: absolute;
		top: 20px;
		left: 20px;
		z-index: -1;
		width: 91%;
		height: 100%;
		background-color: #fafafa;
		content: '';
	}
}

// Font Awesome
// ---------------------
@mixin fontAwesome($content) {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: $content;
}

// responsive system
// ---------------------
@mixin breakpoint($point) {
	@if $point == tablet {
		@media (min-width: 640px) {
			@content;
		}
	}
	@if $point == pc {
		@media (min-width: 890px) {
			@content;
		}
	}
}

.pc-only {
	display: none;
}
@include breakpoint(tablet) {
}
@include breakpoint(pc) {
	.pc-only {
		display: inline-block;
	}
}

@use '../../foundation/system' as system;

.p-searchMedicine {
  &__input {
    margin: 0 0 100px;

    &__container {
      margin: 50px 0 38px;
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 25px 0 15px;
      @include system.formBackground();

      form {
        width: 100%;
        max-width: 85%;
        margin: 0 auto;
        padding: 0 0 25px;
    
        > label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 10px;
          @include system.text-bold(11px);
        }
    
        select {
          border: 1px solid #a5a5a5;
          @include system.text-base(10px);
        }
    
        > div:nth-of-type(4),
          div:nth-of-type(6),
          div:nth-of-type(8),
          div:nth-of-type(10) {
          margin: 0;
        }
      }
    }

    &__checkbox {
      > div {
        display: flex;
        margin: 0 0 10px;

        label {
          margin: 20px 0 0;
        }

        label + label {
          margin: 20px 0 0 25px;
        }
      }
    }
  }

  .c-selectBox {
    margin: 0 0 20px;
  }

  .l-formInput__date {
    div {
      position: relative;
      display: flex;
      justify-content: space-between;
      

      input {
        width: calc((100% - 33px) / 2);
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        content: '〜';
      }
    }
  }

  &__result {
    @include system.searchResultTitle()
  }

  &__list {
    margin: 0 0 100px;

    ul {
      margin: 50px 0 110px;
    }

    li {
      background-color: system.$c-gray;

      & + li {
        margin: 30px 0 0;
      }

      a {
        position: relative;
        display: block;
        width: 100%;
        padding: 20px 25px 45px;

        &::after {
          position: absolute;
          bottom: 16px;
          right: 10px;
          width: 180px;
          height: 7px;
          background: no-repeat url(/assets/img/listArrow.svg);
          background-size: 100%;
          content: '';
        }
      }

      p {
        margin: 0 0 10px;
        @include system.text-bold(12px);
        color: system.$c-blue;
      }

      span {
        display: inline;
        padding: 0 5px 0 0;

        & + span {
          padding: 0 5px;
          border-left: 1px solid system.$c-blue;
        }
      }
    }

    dl {
      display: flex;

      & + dl {
        margin: 5px 0 0;
      }
    }
  }

  &__detail {
    margin: 0 0 100px;

    > dl {
      margin: 0 0 45px;

      > div {
        margin: 35px 0 0;

        &:last-child {
          dd {
            border-bottom: unset;
          }
        }
      }

      dd {
        line-height: 18px;
      }
    }

    &__title {
      width: 270px;
      padding: 0 0 0 40px;
      background-color: system.$c-gray;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      @include system.text-bold();
      line-height: 25px;

      &.red {
        background-color: #FFE7E7;
      }
    }

    &__text {
      width: calc(100% - 70px);
      margin: 15px auto 0;
      padding: 0 0 10px;
      border-bottom: system.$border-gray;

      &.red {
        color: system.$c-red;
      }

      > div {
        margin: 10px 0 0;
        white-space: pre-wrap;
      }

      &.flex {
        white-space: pre-wrap;

        div {
          margin: 10px 0 0;
          white-space: pre-wrap;
        }

        span {
          @include system.text-bold();

        }
      }
    }

    &__detail {
      dt {
        @include system.text-bold();
      }

      dd {
        white-space: pre-wrap;
      }

      div {
        margin: 10px 0 0;
      }
    }

    .c-linkText {
      display: flex;
      justify-content: center;
    }
  }
}
@use '../../foundation/system' as system;

.c-sectionTitle {
  @include system.title-base();

  &::before {
    margin: 0 7px 0 0;
    @include system.fontAwesome("\f129");
    font-size: 18px;
    color: system.$c-brown;
  }
}
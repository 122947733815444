@use '../foundation/system' as system;

.l-header {
  width: 100%;
  margin: 0 auto;
  background-color: system.$c-lightGreen;

  &__logo {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    @include system.content-maxwidth();
    height: 60px;
    margin: 0 auto;

    .logo-img {
      max-width: 170px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 56px;
      height: 28px;
      background: no-repeat url(/assets/img/header1.svg);
      background-size: 100%;
      content: '';
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 50%;
      background-color: system.$c-white;
      content: '';
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 220px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 340px;
    padding: 0 0 8px;
    border-bottom: 1px solid #b8b8b8;
    @include system.text-bold(18px);
    color: #4b4b4b;
    text-align: center;
  }

  &__text {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    @include system.text-bold();
    color: #4b4b4b;
    text-align: center;
  }

  &__user {
    position: relative;
    display: block;
    padding: 20px 0;
    @include system.text-bold(10px);
    text-align: center;

    @mixin line() {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 50px;
      height: 1px;
      background-color: system.$c-black;
      content: '';
    }

    &::before {
      @include line();
      left: 20px;
    }

    &::after {
      @include line();
      right: 20px;
    }
  }
}
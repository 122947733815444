@use '../../foundation/system' as system;

.p-newsList {
  article {
    margin: 50px 0;
  }

  section:nth-of-type(1) {
    @include system.content-maxwidth();
    padding: 0;
    margin: 50px auto 30px;

    h2 {
      margin: 0 27px;
      @include system.titleLine(220px);
    }
  }

  > div {
    margin: 90px 0 80px;
  }

  &__mypage {
    > div {
      margin: 88px 0;
    }
    
    .c-linkText {
      margin: 70px 0 0;
    }
  }
}
@use '../../foundation/system' as system;

.c-textLinkBlue {
  display: flex;
  justify-content: center;

  a {
    padding: 5px;
    @include system.text-base(12px, bold);
    color: system.$c-skyBlue;
  }
}
@use '../../foundation/system' as system;

.p-store {

  &__detail {
    margin: 50px 0 123px;
    padding: 0 36px;

    h2 {
      position: relative;
      padding: 0 0 10px;
      @include system.title-base();

      &::before {
        margin: 0 5px 0 0;
        @include system.fontAwesome("\f484");
        color: system.$c-brown;
        font-size: 20px;
      }
  
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 250px;
        height: 1px;
        background-color: #B8B8B8;
        content: '';
      }
    }
  }

  &__map {
    margin: 30px 0 0;

    div {
      width: 100%;
      height: 300px;
      background-color: system.$c-gray;
    }

    button {
      display: flex;
      justify-content: center;
      width: 130px;
      margin: 9px 0 0;
      padding: 5px 0;
      border: 2px solid system.$c-blue;
      border-radius: 20px;
      @include system.text-base(10px);
      color: system.$c-blue;
    }

    span::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome("\f3c5");
    }
  }

  dl {
    margin: 34px 0 66px;
  }

  &__content {
    margin: 0 0 30px;

    a {
      color: system.$c-blue;
    }
  }

  &__phone {
    display: flex;

    dd + dd {
      margin: 0 0 0 5px;
      padding: 0 0 0 5px;
      border-left: 1px solid system.$c-black;
    }
  }

  > div {
    margin: 75px 0 100px;
  }
}
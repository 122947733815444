@use '../../foundation/system' as system;

.c-linkText {
  @include system.text-bold(10px);
  color: system.$c-blue;
  text-align: right;

  a {
    color: system.$c-blue;

    &::before {
      display: inline-block;
      margin: 0 5px 0 0;
      content: '≫';
    }
  }

  &.underline {
    margin: 0 10px;

    a {
      border-bottom: 1px dashed system.$c-blue;
    }
  }
}
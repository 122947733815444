@use '../foundation/system' as system;

.p-howto {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 40px;
  padding: 5px 25px;
  background-color: system.$c-lightGreen;

  button {
    @include system.text-base();
    padding: 5px;

    span {
      @include system.text-bold();
      color: system.$c-blue;

      &::before {
        margin: 0 5px 0 0;
        @include system.fontAwesome("\f05a");
        color: system.$c-blue;
      }

      &::after {
        margin: 0 0 0 5px;
        @include system.fontAwesome("\f0dd");
        color: system.$c-blue;
      }
    }
  }

  div {
    max-height: 0;
    padding: 0 20px;
    overflow: hidden;
    @include system.transition-base();
    background-color: system.$c-white;

    p {
      @include system.text-base(14px);

      & + p {
        margin: 10px 0 0;
      }
    }

    &.p-howto__open {
      max-height: 350px;
      margin: 5px 0 15px;
      padding: 20px;
    }

    button {
      display: block;
      width: 157px;
      line-height: 20px;
      margin: 40px auto 0;
      @include system.text-bold();
      color: system.$c-blue;
      text-align: center;
    }
  }
}
@use '../../foundation/system' as system;

.c-contentTitle {
  width: 100%;
  margin: 0 0 10px;
  padding: 0 0 0 10px;
  background-color: system.$c-gray;
  border-left: 3px solid system.$c-blue;
  @include system.title-base();
  line-height: 20px;
}
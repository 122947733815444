@use '../../foundation/system' as system;

.p-signUp {

  &__form {
    margin: 30px 0 100px;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 45px 0 15px;
    @include system.formBackground();

    form {
      width: 100%;
      max-width: 85%;
      margin: 0 auto;
      padding: 0 0 25px;
  
      button {
        margin: 30px auto 0;
      }
    }
  
    > div {
      max-width: 88%;
      margin: 50px 0 0;
    }
  }

  section {
    margin: 60px 0 100px;
  }
}
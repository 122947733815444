@use '../../foundation/system' as system;

.p-searchStores {
  .p-searchStore {
    margin: 45px 0;
  }

  &__result {
    margin: 40px 0 113px;
  }

  &__title {
    @include system.searchResultTitle()
  }
  
  &__list {
    margin: 32px 0 0;
    padding: 30px 20px;
    background-color: system.$c-gray;
  }

  > div {
    margin: 75px 0 110px;
  }
}
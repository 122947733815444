@use '../../foundation/system' as system;

.p-news {
  &__article {
    @include system.content-maxwidth();
    margin: 50px auto 66px;
    padding: 0;

    > div {
      margin: 37px 36px 0
    }

    h2 {
      margin: 15px 20px 0;
      padding: 0 0 8px;
      @include system.text-base(12px, bold);
      border-bottom: 1px dashed system.$c-black;
    }
  }

  &__date {
    @include system.newsTime();
  }

  &__text {
    @include system.text-base();
    margin: 27px 0 0;
  }

  &__img {
    position: relative;
    width: 100%;

    &::before {
      display: block;
      width: 100%;
      padding: 56.25% 0 0;
      content: '';
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__link {
    margin: 40px 0 0;
    
    a {
      padding: 0 0 10px;
      border-bottom: 1px dashed system.$c-blue;
    }
  }

  > div {
    margin: 66px 0 90px;
  }
}
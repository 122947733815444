@use '../foundation/system' as system;

.p-searchStore {
  margin: 0 0 105px;

  h2 {
    @include system.title-base();
    text-align: center;

    &::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome("\f689");
    }
  }

  p {
    @include system.text-base(10px, normal);
    text-align: center;
  }

  form {
    position: relative;
    padding: 20px 0;
    margin: 10px 0 0;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      width: 91%;
      height: 100%;
      background-color: system.$c-lightGreen;
      content: '';
    }
  
    &::before {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: -1;
      width: 91%;
      height: 100%;
      background-color: #fafafa;
      content: '';
    }

    button {
      margin: 30px auto 0;
    }
  }

  &__box1 {
    display: flex;
    justify-content: space-between;
    max-width: 85%;
    margin: 20px auto 0;

    div:nth-of-type(1) {
      width: 40%;
    }

    div:nth-of-type(2) {
      width: calc(60% - 10px);
    }
  }

  &__box2 {
    display: flex;
    justify-content: space-between;
    max-width: 85%;
    margin: 20px auto 0;
    padding: 0 0 10px;
    border-bottom: 1px solid #b8b8b8;

    input {
      width: 100%;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 33.5px;
    margin: 10px 0 0;
    background-color: #D1EFFF;
    border: system.$border-gray;
    border-radius: 5px;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}
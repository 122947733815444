@use '../../foundation/system' as system;

.p-tipsList {
  article {
    margin: 50px 0;
  }

  section:nth-of-type(1) {
    @include system.content-maxwidth();
    padding: 0;
    margin: 50px auto 30px;

    h2 {
      margin: 0 27px;
      @include system.titleLine(175px);
    }
  }

  > div {
    margin: 90px 0 80px;
  }

  &__categoryTitle {
    display: inline-block;
    margin: 43px 0 57px 22px;
    padding: 6px 18px;
    background-color: system.$c-blue;
    border-radius: 20px;
    @include system.text-bold(10px);
    color: system.$c-white;

    &::before {
      margin: 0 2px 0 0;
      @include system.fontAwesome('\f02b');
    }
  }
}
@use '../../foundation/system' as system;

.c-formMessage {
  width: calc(100% - 60px);
  margin: 30px auto 0;
  padding: 10px 12px;
  border: 1px dashed #b8b8b8;
  @include system.text-base();
  line-height: 1.3;

  &__bold {
    @include system.text-bold();

    span {
      color: system.$c-blue;
    }
  }

  p + p {
    margin: 20px 0 0;
  }
}
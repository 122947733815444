@use '../../foundation/system' as system;

.c-linkButton {
  // position: relative;
  width: 330px;
  height: 50px;

  button,
  a {
    display: inline-block;
    width: 100%;
    padding: 0 20px;
    @include system.text-bold(14px);
    line-height: 50px;
  }

  span::after {
    margin: 0 0 0 20px;
    @include system.fontAwesome('\f35a');
    font-size: 18px;
    font-weight: 400;
  }

  &.red {
    margin: 0 0 0 auto;
    background-color: system.$c-red;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: system.$c-white;

    span {
      color: system.$c-white;
    }
  }

  &.blue {
    color: system.$c-blue;

    button,
    a {
      border: 3px solid system.$c-blue;
      border-left: unset;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    span {
      color: system.$c-blue;
    }
  }

  &.allBlue {
    width: 275px;
    margin: 0 0 0 auto;
    background-color: system.$c-blue;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: system.$c-white;

    span {
      color: system.$c-white;
    }
  }

  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fffa;
    z-index: 100;
    > span {
      &::after {
        display: none;
      }
    }
  }
}

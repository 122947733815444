@use "../../foundation/system" as system;

.c-message {
  position: relative;
  margin: 47px 0 0;
  @include system.title-base();
  text-align: center;

  &::before {
    margin: 0 5px 0 0;
    @include system.fontAwesome("\f00c");
    color: system.$c-brown;
  }
}

.c-message-cross {
  position: relative;
  margin: 47px 0 0;
  @include system.title-base();
  text-align: center;

  &::before {
    margin: 0 5px 0 0;
    @include system.fontAwesome("\f00d");
    color: system.$c-brown;
  }
}

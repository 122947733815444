@use '../../foundation/system' as system;

.p-reserve {
  &__list {
    margin: 0 0 100px;

    ul {
      margin: 80px 0 0;
    }
  }

  form {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    padding: 0 0 25px;
  }

  &__new {
    margin: 0 0 100px;

    &__container {
      margin: 30px 0 38px;
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 25px 0 15px;
      @include system.formBackground();
    }

    &__prescription {
      border-bottom: unset !important;

      label {
        display: flex;
        width: calc(100% + 15%);
        margin: 15px -7.5% 0;
        padding: 10px 10px 10px 25px;
        background-color: #FFE7E7;
        box-shadow: 0px 2px 4px rgba(177, 124, 124, 0.25);
      }

      dd {
        width: calc(100% + 80px);
        margin: 15px -40px 0 !important;
        padding: 10px 10px 10px 25px;
        background-color: #FFE7E7;
        box-shadow: 0px 2px 4px rgba(177, 124, 124, 0.25);
      }

      .prescription-pagination {
        margin-top: 20px;
      
        input {
          display: inline;
        }
      }
    }
  }

  &__reserve {
    margin: 0 0 100px;

    > dl {
      margin: 0 0 74px;
    }

    article {
      margin: 100px 0 60px;
    }
  }
}
@use "../../foundation/system" as system;

.p-calendar {
  margin: 0 0 100px;

  &__calendar {
    width: 100%;
    margin: 20px 0;
    background-color: system.$c-gray;
  }
}

table.ant-picker-content > tbody > tr > td {
  border: 1px solid #cccccc !important;
}

table.ant-picker-content > thead > tr > th {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

table.ant-picker-content > thead > tr > th {
  text-align: center;
}

table.ant-picker-content > thead > tr > th {
  color: white;
}

.ant-picker-panel {
  background-color: #3d4754 !important;
}

.ant-picker-content > tbody {
  background-color: #ffffff !important;
}

.ant-picker-cell-inner {
  background: transparent !important;
}

.ant-picker-calendar-date-today::before {
  border: none !important;
}
.ant-picker-calendar-date-value {
  background-color: none !important;
}

table.ant-picker-content > tbody > tr > td.ant-picker-cell-today {
  background: #eeeeee !important;
}

table.ant-picker-content > tbody > tr > td.ant-picker-cell-selected {
  background: #f4ab7e !important;
}
.ant-picker-date-panel {
  margin-bottom: -8px;
}

td.ant-picker-cell-in-view:hover {
  background: #eeeeee !important;
}
// #f4f4f4

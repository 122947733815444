@use '../foundation/system' as system;

.l-footer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px 20px 0;
  background-color: system.$c-dark;

  &__btn {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    background-color: system.$c-white;
    border-radius: 10px;

    &::before {
      font-size: 40px;
      @include system.fontAwesome("\f139");
      color: system.$c-dark;
    }
  }

  &__logo {
    width: 118px;
    margin: 0 0 30px 20px;

    img {
      width: 100%;
    }
  }

  ul {
    margin: 20px 0 0;
    display: flex;

    li {
      padding: 0 15px;
      text-align: center;

      & + li {
        border-left: 1px solid system.$c-white;
      }

      a {
        color: system.$c-white;
      }
    }
  }

  &__nav {
    @include system.text-base(10px);
  }

  small {
    display: block;
    margin: 40px 0 0;
    @include system.text-base(10px);
    color: system.$c-white;
    text-align: right;
  }

  &__signOut {
    display: flex;
    align-items: center;

    div {
      margin: 0 24px 0 20px;
    }
  }

  &__mypage {
    width: calc(100% - 40px);
    margin: 30px auto 0;

    > a {
      display: block;
      margin: 17px 0 0;
    }
  }

  &__book {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      width: 75px;
      @include system.text-bold();
    }

    ul {
      flex-wrap: wrap;
      width: calc(100% - 100px);
      margin: 0;

      li:nth-of-type(3) {
        margin: 17px 0 0;
        border: unset;
      }

      li:nth-of-type(4) {
        margin: 17px 0 0;
      }
    }
  }

  &__user {
    margin: 17px 0 0;

    li:nth-of-type(1) {
      padding: 0 15px 0 0;
    }
  }
}
@use '../../foundation/system' as system;

.p-memo {
  &__list ul {
    margin: 50px 0 100px;
  }

  &__new {
    margin: 0 0 100px;

    &__container {
      margin: 30px 0 38px;
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 45px 0 15px;
      @include system.formBackground();
    }

    form {
      width: 100%;
      max-width: 85%;
      margin: 0 auto;
      padding: 0 0 25px;

      button {
        margin: 50px auto 0;
      }
    }
  }

  &__update {
    margin: 0 0 100px;

    > dl {
      margin: 0 0 80px;
    }

    form {
      width: 100%;
      max-width: 85%;
      margin: 0 auto;
      padding: 0 0 25px;

      button {
        margin: 50px auto 0;
      }
    }
  }

  &__message {
    h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      @include system.title-base();

      &::before {
        margin: 0 10px 0 0;
        @include system.fontAwesome("\f0e0");
        font-size: 30px;
      }
    }

    div {
      margin: 7px 0 0;
      padding: 17px 20px;
      background-color: #F2FFF4;

      &.user {
        background-color: #F4F4F4;

        dl {
          box-shadow: unset;
        }
      }

      & + div {
        margin: 20px 0 0;
      }
    }

    dl {
      padding: 20px 25px;
      background-color: system.$c-white;
      box-shadow: 1px 1px 2px rgba(132, 163, 136, 0.25);
    }

    dt {
      margin: 0 0 13px;
      @include system.text-base(14px);
    
      &::before {
        margin: 0 5px 0 0;
        @include system.fontAwesome("\f017");
        font-weight: 400;
      }
    
      span:nth-of-type(1) {
        display: inline-block;
        margin: 0 5px 0 0;
      }
    }
  }
}
@use '../../foundation/system' as system;

.p-tips {
  &__articleArea {
    @include system.content-maxwidth();
    margin: 50px auto 136px;
    padding: 0;

    h2 {
      width: 320px;
      margin: 15px 20px 0;
      padding: 0 0 8px;
      @include system.text-base(12px, bold);
      border-bottom: system.$border-gray;
    }
  }

  &__date {
    display: flex;
    align-items: center;

    > span {
      margin: 0 0 0 12px;
      @include system.text-bold(10px);

      &::before {
        margin: 0 2px 0 0;
        @include system.fontAwesome('\f02b');
      }
    }

    p {
      @include system.newsTime();
    }
  }

  &__article {
    width: calc(100% - 72px);
    margin: 30px auto 0;
  }

  &__img {
    position: relative;
    width: 100%;

    &::before {
      display: block;
      width: 100%;
      padding: 56.25% 0 0;
      content: '';
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    p {
      @include system.text-base();
      margin: 27px 0 0;
    }
  }

  &__same {
    h3 {
      position: relative;
      padding: 0 18px 10px;

      &::after {
        position: absolute;
        bottom: 0;
        left: 18px;
        width: 175px;
        height: 1px;
        background-color: #b8b8b8;
        content: '';
      }
    }
  }

  &__category {
    margin: 137px 0 132px;
    padding: 0 8px;

    h3 {
      position: relative;
      margin: 0 0 30px;
      padding: 0 0 15px;
      @include system.title-base();
      text-align: center;
    
      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 25px;
        height: 3px;
        background-color: system.$c-brown;
        content: '';
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin: 12px 5px 0 0;
    }

    a {
      display: block;
      padding: 6px 18px;
      @include system.text-bold(10px);
      color: system.$c-blue;
      background-color: #F4F4F4;
      border-radius: 20px;

      &::before {
        margin: 0 2px 0 0;
        @include system.fontAwesome('\f02b');
      }
    }
  }

  > div {
    margin: 75px 0 91px;
  }
}
@use '../foundation/system' as system;

.l-topMenu {
  margin: 30px auto 54px;
  padding: 0 10px;

  h2 {
    @include system.text-bold(18px);
    color: system.$c-brown;
    text-align: center;
    
    &::before {
      margin: 0 10px 0 0;
      @include system.fontAwesome("\f518");
      color: system.$c-brown;
    }
  }

  &__list1 {
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0;
    max-width: 370px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 70px;
      border: 3px solid system.$c-brown;
      border-radius: 10px;
      @include system.text-bold(10px);
      color: system.$c-brown;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .history::before {
      @include system.fontAwesome('\f15b');
      font-size: 25px;
    }
    
    .carendar::before {
      @include system.fontAwesome('\f133');
      font-size: 25px;
    }
    
    .memo::before {
      @include system.fontAwesome('\f500');
      font-size: 25px;
    }

    .reserve::before {
      @include system.fontAwesome('\f017');
      font-weight: 400;
      font-size: 25px;
    }
  }

  &__list2 {
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0;
    max-width: 370px;

    li {
      width: 115px;
      height: 70px;
      border: 2px solid system.$c-brown;
      border-radius: 10px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: system.$c-brown;
      border: 2px solid system.$c-white;
      border-radius: 10px;
      @include system.text-bold();
      color: system.$c-white;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .teach::before {
      @include system.fontAwesome('\f086');
      font-size: 28px;
    }

    .search::before {
      @include system.fontAwesome('\f484');
      font-size: 28px;
    }

    .user::before {
      @include system.fontAwesome('\f4fe');
      font-size: 28px;
    }
  }
}
@use '../../foundation/system' as system;

.c-selectBox {
  padding: 0 0 10px;
  border-bottom: 1px solid #b8b8b8;

  &__container {
    position: relative;
    background-color: system.$c-white;

    select {
      position: relative;
      z-index: 2;
      display: block;
      width: 100%;
      padding: 10px 20px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
      @include system.text-base(10px);
      color: #929292;

      &.error {
        border: 1px solid system.$c-red;
      }

      &.selected {
        color: system.$c-black;
      }
    }

    &::after {
      position: absolute;
      top: 45%;
      right: 12px;
      z-index: 1;
      transform: translate(0, -50%);
      @include system.fontAwesome("\f0dd");
    }

    &.address {
      select {
        border: 1px solid #a5a5a5;
        box-shadow: unset;
        color: system.$c-black;
      }

      select.error {
        border: 1px solid system.$c-red;
      }
    }

    &.year {
      width: 40%;

      select {
        border: 1px solid #a5a5a5;
        box-shadow: unset;
        color: system.$c-black;
      }

      select.error {
        border: 1px solid system.$c-red;
      }
    }

    &.date {
      width: 25%;

      select {
        border: 1px solid #a5a5a5;
        box-shadow: unset;
        color: system.$c-black;
      }

      select.error {
        border: 1px solid system.$c-red;
      }
    }
  }

  &.black {
    margin: 15px 0 0;
    padding: 0;
    border-bottom: unset;

    select {
      padding: 12.25px 20px;
      border: 1px solid #a5a5a5;
      box-shadow: unset;
      color: system.$c-black;

      &.error {
        border: 1px solid system.$c-red;
      }
    }

    .c-selectBox__container::after {
      right: 25px;
    }
  }

  &.border {
    border-bottom: unset;

    select {
      box-shadow: unset;
    }
  }

  &__orig {
    display: flex;
    justify-content: space-between;
    margin: 12px 0 0;
  }
}
@use '../../foundation/system' as system;

.c-mainButton {
  display: block;
  width: 300px;
  height: 35px;
  margin: 0 auto;
  @include system.title-base();
  color: system.$c-white;
  text-align: center;

  &.blue {
    border-radius: 20px;
    background-color: system.$c-blue;

    &.min {
      font-size: 10px;
    }
  }

  &.green {
    background-color: #3BA12B;
    font-size: 11px;
  }

  &.white {
    background-color: system.$c-white;
    color: #3457D6;
  }

  &.black {
    border-radius: 20px;
    background-color: system.$c-dark;
  }

  &.link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 90px auto 100px;
    border-radius: 20px;
    background-color: system.$c-blue;
    font-size: 10px;
  }

  &.photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    margin: 0;
    background-color: system.$c-white;
    border: 2px solid system.$c-blue;
    border-radius: 20px;
    color: system.$c-blue;
    font-size: 10px;

    &.center {
      width: 300px;
      margin: 30px auto 40px;
    }
  }
}
@use '../../foundation/system' as system;

.p-contact {
  margin: 55px auto 30px;

  &__container {
    margin: 30px 0 100px;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 45px 0 15px;
    @include system.formBackground();
  }

  h2 {
    margin: 0 26px;
    @include system.title-base();
    line-height: 20px;

    &::before {
      margin: 0 6px 0 0;
      @include system.fontAwesome("\f303");
      color: system.$c-brown;
    }
  }

  &__input {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    padding: 0 0 25px;

    button {
      margin: 30px auto 0;
    }

    > label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px;
      @include system.text-base(10px, bold);
      color: #6C6C6C;
    }

    button {
      width: 100%;
      margin: 30px auto 0;
    }

    > div {
      margin: 0 0 30px;
    }
  }

  &__confirm {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    width: 100%;
    height: 100vh;
    padding: 30px 0 0;
    background: rgba(241, 255, 244, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    h2 {
      @include system.text-bold(24px);
      color: system.$c-black;
      text-align: center;
      border: unset;
    }
  }

  &__closeBtn {
    position: absolute;
    top: 20px;
    left: 20px;
    display: block;
    width: 40px;
    height: 40px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      @include system.fontAwesome("\f057");
      font-size: 40px;
      font-weight: 400;
    }
  }

  &__table {
    width: 100%;
    max-width: 300px;
    margin: 30px auto 0;
    padding: 30px 0;
    background-color: system.$c-white;

    dl {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px;

      > div + div {
        margin: 40px 0 0;
      }
    }

    dt {
      @include system.text-bold(18px);
    }

    dd {
      margin: 10px 0 0;
      @include system.text-base(14px);
    }

    button {
      width: 250px;
      margin: 62px auto 0;
    }
  }
}
@use '../../foundation/system' as system;

.c-newsPageList {
  position: relative;
  display: block;
  margin: 30px 0 0;

  dt {
    @include system.newsTime();
  }

  dd {
    margin: 10px 27px 0;
    padding: 0 0 24px;
    @include system.text-bold();
    border-bottom: 1px solid #b8b8b8;
    color: system.$c-blue;
  }
}
@use '../foundation/system' as system;

.l-signIn {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 45px 0 15px;
  margin: 0 0 50px;
  @include system.formBackground();

  &__error {
    display: block;
    width: 85%;
    margin: 0 auto 30px;
    @include system.text-base(10px);
    color: system.$c-red;
    line-height: 1.5;
  }

  h2 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    @include system.title-base();
  
    span {
      position: relative;
      padding: 0 0 0 30px;
      text-align: start;

      &::before {
        position: absolute;
        top: 65%;
        left: 0;
        transform: translate(0, -50%);
        width: 26px;
        height: 26px;
        @include system.fontAwesome("\f2f6");
      }
    }
  }

  form {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    padding: 0 0 25px;

    button {
      margin: 30px auto 0;
    }
  }

  > div {
    max-width: 88%;
    margin: 50px 0 0;
  }
}
@use '../../foundation/system' as system;

.p-prescription {
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fffa;
    > span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #000;
    }
  }

  &__history {
    ul {
      margin: 108px 0 55px;
    }
  }

  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 27px 0;
    border: 1px dashed system.$c-red;

    span {
      width: 300px;
      margin: 0 auto;
      @include system.text-bold(11px);
      line-height: 1.5;
    }
  }

  &__detail {
    margin: 40px 0 120px;

    dl {
      width: calc(100% - 40px);
      margin: 40px auto 80px;

      > div + div {
        margin: 30px 0 0;
      }
    }

    dd {
      padding: 0 15px;
      @include system.text-base(14px);

      & + dd {
        margin: 5px 0 0;
      }

      div {
        width: 100%;
        height: 250px;
        background-color: system.$c-gray;
      }

      span {
        display: inline-block;
        margin: 0 0 0 5px;
        @include system.text-bold(11px);
        color: system.$c-red;
        line-height: 17.6px;
      }
    }
  }

  &__new {
    margin: 0 0 100px;

    &__container {
      margin: 80px 0 38px;
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 45px 0 15px;
      @include system.formBackground();
    }

    form {
      width: 100%;
      max-width: 85%;
      margin: 0 auto;
      padding: 0 0 25px;

      > label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px;
        @include system.text-bold(12px);
      }

      button {
        margin: 50px auto 0;
      }
    }
  }

  &__confirm {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    width: 100%;
    height: 100vh;
    padding: 30px 0 0;
    background: rgba(241, 255, 244, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    overflow-y: scroll;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    h2 {
      @include system.text-bold(24px);
      color: system.$c-black;
      text-align: center;
      border: unset;
    }
  }

  &__closeBtn {
    position: absolute;
    top: 20px;
    left: 20px;
    display: block;
    width: 40px;
    height: 40px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      @include system.fontAwesome('\f057');
      font-size: 40px;
      font-weight: 400;
    }
  }

  &__table {
    width: 100%;
    max-width: 360px;
    margin: 30px auto;
    padding: 30px 0;
    background-color: system.$c-white;

    dl {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px;

      div {
        padding: 0 0 10px;
        border-bottom: 1px solid #b8b8b8;
      }

      > div + div {
        margin: 40px 0 0;
      }
    }

    dt {
      @include system.text-bold(18px);
    }

    dd {
      margin: 10px 0 0;
      @include system.text-base(14px);
    }

    button {
      width: 250px;
      margin: 62px auto 0;
    }
  }
}

@use '../../foundation/system' as system;

.c-textarea {
  width: 100%;
  height: 250px;
  margin: 10px 0 0;
  padding: 10px 20px;
  background-color: system.$c-white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  resize: none;
  @include system.text-base(10px, normal);

  &::placeholder {
    @include system.text-base(10px, normal);
    color: #929292;
  }

  &.error {
    border: 1px solid system.$c-red;
  }

  &__border {
    width: 100%;
    height: 250px;
    margin: 10px 0 0;
    padding: 10px 20px;
    background-color: system.$c-white;
    border: 1px solid #A5A5A5;
    resize: none;
    @include system.text-base(10px, normal);
  
    &::placeholder {
      @include system.text-base(10px, normal);
      color: #929292;
    }
  
    &.error {
      border: 1px solid system.$c-red;
    }

    &.min {
      height: 150px;
    }
  }
}
@use '../foundation/system' as system;

.l-headerNav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;

  > div {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; 
  }

  &__openBtn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: system.$c-lightGreen;

    span {
      @include system.text-base(10px, normal);
      color: system.$c-blue;
    }

    &::before {
      @include system.fontAwesome("\f0c9");
      font-size: 20px;
      color: system.$c-blue;
    }
  }

  nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    padding: 30px 0;
    background: rgba(244, 244, 244, 0.75);
    opacity: 0;
    visibility: hidden;
    @include system.transition-base();
    overflow-y: scroll;

    &.l-headerNav__open {
      opacity: 1;
      visibility: visible;
    }

    p {
      @include system.text-bold(24px);
      text-align: center;
    }
  }

  &__closeBtn {
    position: absolute;
    top: 20px;
    left: 20px;
    display: block;
    width: 40px;
    height: 40px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      @include system.fontAwesome("\f057");
      font-size: 40px;
      font-weight: 400;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: 30px auto 0;
    padding: 50px 0;
    background-color: system.$c-white;

    a {
      display: flex;
      align-items: center;
      @include system.text-bold(18px);
      color: system.$c-black;

      &::before {
        margin: 0 10px 0 0;
        @include system.fontAwesome("\f141");
        color: system.$c-brown;
      }

      &.out {
        width: 190px;
      }

      &.in {
        width: 250px;
      }
    }

    li + li {
      margin: 35px 0 0;
    }
  }
}
@use '../../foundation/system' as system;

.c-tipsPageList {
  margin: 20px 0 0;

  a {
    display: block;
  }

  &__top {
    display: flex;
    align-items: center;

    > span {
      margin: 0 0 0 12px;
      @include system.text-bold(10px);

      &::before {
        margin: 0 2px 0 0;
        @include system.fontAwesome('\f02b');
      }
    }

    p {
      @include system.newsTime();
    }
  }

  dt {
    margin: 12px 20px 0;
    @include system.text-bold();
    color: system.$c-blue;
  }

  dd {
    margin: 16px 20px 0;
    @include system.text-base(11px)
  }

  & + .c-tipsPageList {
    position: relative;
    padding: 18px 0 0;

    &::after {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: calc(100% - 40px);
      height: 1px;
      background-color: #b8b8b8;
      content: '';
    }
  }

  &__error {
    dt {
      margin: 12px 20px 0;
      color: system.$c-red;
    }
  
    dd {
      margin: 5px 20px 0;
      color: system.$c-red;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin: 76px 0 138px;

    li:first-child,
    li:last-child {
      margin: 0 10px;
    }

    .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root {
      border: 1px solid system.$c-blue;
      color: system.$c-blue;
    }

    .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
    .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
      border: 1px solid system.$c-black;
      background-color: system.$c-black;
      color: system.$c-white;
    }
  }
}
@use '../../foundation/system' as system;

.p-mypage {

  &__reserve {
    h2 {
      margin: 0 25px 20px;
      @include system.titleLine(182px);
    }
  }
}
@use '../../foundation/system' as system;

.c-input {
  margin: 10px 0 0;
  padding: 10px 20px;
  background-color: system.$c-white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  @include system.text-base(10px, normal);

  &::placeholder {
    @include system.text-base(10px, normal);
    color: #929292;
  }

  &.error {
    border: 1px solid system.$c-red;
  }

  &.file {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 37px;
    margin: 10px 0 0;
    background-color: #CACACA;
    border: 1px solid #A5A5A5;
    cursor: pointer;
    @include system.text-base(10px);

    &.error {
      border: 1px solid system.$c-red;
    }

    input {
      opacity:0;
      height: 1px;
      width: 1px;
      -webkit-appearance: none;
      appearance: none;
    }
  }
}
@use '../../foundation/system' as system;

.p-articleList {
  position: relative;
  display: block;
  margin: 30px 0 0;
  padding: 0 0 20px;

  dt {
    @include system.text-base(10px);

    &::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome("\f017");
      font-weight: 400;
    }
  }

  &__date {
    display: inline-block;
    margin: 0 5px 0 0;
  }

  &__category {
    display: inline-block;
    margin: 0 0 0 20px;
  }

  dd {
    margin: 10px 0 0;
    @include system.text-bold();
    color: system.$c-blue;
  }

  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 7px;
    background: no-repeat url(/assets/img/listArrow.svg);
    background-size: 100%;
    content: '';
  }

  &__error {
    color: system.$c-red;

    dt {
      width: 100%;
    }

    dd {
      width: 100%;
      margin: 5px 0 0;
    }
  }
}
@use '../foundation/system' as system;

.l-confirm {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: 100vh;
  padding: 30px 0 0;
  background: rgba(241, 255, 244, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  overflow-y: scroll;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  h2 {
    @include system.text-bold(24px);
    color: system.$c-black;
    text-align: center;
    border: unset;
  }

  &__closeBtn {
    position: absolute;
    top: 20px;
    left: 10px;
    display: block;
    width: 40px;
    height: 40px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      @include system.fontAwesome("\f057");
      font-size: 40px;
      font-weight: 400;
    }
  }

  &__table {
    width: 100%;
    max-width: 360px;
    margin: 30px auto;
    padding: 30px 0;
    background-color: system.$c-white;

    dl {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px;

      > div {
        padding: 0 0 10px;
        border-bottom: 1px solid #b8b8b8;
      }

      > div + div {
        margin: 40px 0 0;
      }
    }

    dt {
      @include system.text-bold(18px);
    }

    dd {
      margin: 10px 0 0;
      @include system.text-base(14px);
    }

    button {
      width: 250px;
      margin: 62px auto 0;
    }
  }

  &__img {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
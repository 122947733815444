@use '../../foundation/system' as system;

.c-reserve {
  a {
    position: relative;
    display: block;
    padding: 20px 25px 36px;

    &::after {
      position: absolute;
      bottom: 16px;
      right: 10px;
      width: 180px;
      height: 7px;
      background: no-repeat url(/assets/img/listArrow.svg);
      background-size: 100%;
      content: '';
    }
  }

  &__time {
    @include system.text-base(16px);

    &::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome("\f017");
      font-weight: 400;
    }

    span {
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }

  &__store {
    margin: 15px 0 0;
    @include system.title-base();
    color: system.$c-blue;
  }

  @mixin redText($content, $weight, $size) {
    display: flex;
    align-items: center;
    margin: 10px 0 0;
    @include system.title-base();
    color: system.$c-red;

    &::before {
      margin: 0 12px 0 0; 
      @include system.fontAwesome($content);
      font-size: $size;
      font-weight: $weight;
    }
  }

  &__message {
    @include redText('\f0e0', 900, 30px)
  }

  &__reserve {
    @include redText('\f017', 400, 30px)
  }

  &__teach {
    @include redText('\f500', 900, 25px)
  }

  @mixin rabel() {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 1;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 40px;
    @include system.text-bold(10px);
    color: system.$c-white;
  }

  &.red {
    a {
      background-color: #FFE7E7;
      box-shadow: 0px 2px 4px rgba(177, 124, 124, 0.25);

      &::before {
        @include rabel();
        background-color: system.$c-red;
        content: '未訪問';
      }
    } 
  }

  &.gray {
    a {
      background-color: #F5F5F5;

      &::before {
        @include rabel();
        background-color: #7D7D7D;
        content: '再訪';
      }
    }
  }

  & + li {
    margin: 45px 0 0;
  }

  .info + .info {
    margin: 0;
    padding: 5px 0 0;
    border-top: 1px dashed #B8B8B8;
  }
}
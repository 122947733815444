@use '../../foundation/system' as system;

.p-home {
  &__line {
    display: block;
    margin: 0 0 5px;
    @include system.text-base(10px);
    color: #737373;
    text-align: center;
  }

  &__news {
    @include system.content-maxwidth();
    margin: 50px auto 30px;
  }

  &__tips {
    @include system.content-maxwidth();
    margin: 70px auto;

    div {
      margin: 27px 0 0;
    }
  }

  &__signout {
    @include system.text-bold(14px);
    text-align: center;
    
    &::before {
      margin: 0 5px 0 0;
      @include system.fontAwesome("\f06a");
      color: system.$c-brown;
    }
  }

  .c-formMessage {
    margin: 30px auto 60px;
  }
}
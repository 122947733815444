@use '../../foundation/system' as system;

.c-sectionGrayBack {
  width: 100%;
  padding: 20px 27px;
  background-color: system.$c-gray;

  h2 {
    @include system.title-base();
  }
}
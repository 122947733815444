@use '../../foundation/system' as system;

.c-itemsList {
  dt {
    width: 180px;
    padding: 0 0 0 30px;
    background-color: #f4f4f4;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    @include system.title-base(13px);
    line-height: 25px;
  }

  &__text {
    width: calc(100% - 60px);
    margin: 15px auto 0;
    padding: 0 0 10px;
    border-bottom: system.$border-gray;
    @include system.text-base(13px);

    span {
      display: block;
      line-height: 1.5;
    }

    &.wrap {
      white-space: pre-wrap;
    }

    .c-linkText {
      text-align: left;
      @include system.text-base(13px);
      color: system.$c-blue;
    }
  }

  & + div {
    margin: 20px 0 0;
  }

  &__red {
    width: 100%;
    margin: 15px 0 0;
    padding: 10px 30px;
    background-color: #FFE7E7;
    box-shadow: 0px 2px 4px rgba(177, 124, 124, 0.25);
  }

  &__limit {
    display: inline-block;
    margin: 0 0 0 10px;
    @include system.text-bold(11px);
    color: system.$c-red;
  }

  &:last-child {
    .c-itemsList__text {
      border-bottom: unset;
    }
  }
}